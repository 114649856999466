import { React, useState } from "react";
import FishDate from "../FishIn/FishDate";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FishOutNumber from "./FishOutNumber";
import { FormattedMessage } from "react-intl";
import "./FishOutItem.css";
import TextTruncate from "react-text-truncate";
//import background from "./BackgroundInbox.png";
const FishOutItem = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <tr
        onClick={handleShow}
        // background={background}
        // width="100%"
        // height="105px"
      >
        <td width="10%" align="center">
          <FishDate date={props.date} />
        </td>
        <td width="15%" align="left" className="fishSentName">
          {props.name}
        </td>
        <td width="20%" align="left">
          <FishOutNumber fishcount={props.fishcount} />
        </td>
        <td width="55%" align="left" className="long-message">
          <TextTruncate
            line={2}
            element="span"
            truncateText="..."
            text={props.message}
            textTruncateChild={<a href="#">Read on</a>}
          />
        </td>
      </tr>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="pop-up-modal-header">
          <Modal.Title>
            <h6>
              <FormattedMessage id="to" />
            </h6>
            <h6>{props.name}</h6>
            <h6>
              <FormattedMessage id="date" />
            </h6>
            <h6>{props.date.substring(0, 10)}</h6>
            <h6>
              <FormattedMessage id="fishnumber" />
            </h6>
            <h5>
              <FishOutNumber fishcount={props.fishcount} />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pop-up-modal">
          <h6>
            <FormattedMessage id="message" />
          </h6>
          <p>{props.message}</p>
        </Modal.Body>
        <Modal.Footer className="pop-up-modal">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FishOutItem;
