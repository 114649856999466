const messages = {
  en: {
    selectlanguage: "SELECT LANGUAGE",
    email: "EMAIL",
    rememberme: "REMEMBER ME",
    password: "PASSWORD",
    login: "LOG IN",
    terms:
      'BY CLICKING "LOG IN", YOU HAVE AGREED TO THE TERMS AND CONDITION OF USE',
    date: "DATE",
    sentby: "SENT BY",
    fishnumber: "NO. OF FISHES",
    message: "MESSAGE",
    sentto: "SENT TO",
    logout: "LOG OUT",
    forgetpassword: "FORGET PASSWORD?",
    forgetpasswordalert: "Please check your email for new password",
    FAQs: "FAQs",
    send_fish_to: "SEND FISH TO",
    fish_news: "FISH NEWS",
    fish_sent: " Your FISH message has been sent successfully to {toEmail}!",
    warning:
      "WARNING: Access to information on this machine and network is restricted to authorised personnel only. Any unauthorised user is subjected to criminal prosecution under the Computer Misuse Act 1993.",
    your_message: "YOUR MESSAGE:",
    to: "TO:",
    cc: "CC:",
    send: "SEND",
    from: "FROM",
    incorrectPW: "Invalid email address or password",
    tempPW: "Temporary Password",
    newPW: "New Password",
    confirmPW: "Confirm Password",
    submit: "Submit",
    currentPW: "Current Password",
    changePW: " Change Password",
    passwordTempReq: "Temporary Password is required",
    passwordMinChar: "The new password must be at least 8 characters long.",
    passwordReq: "A new password is required.",
    passwordMatch: "The passwords must match.",
    passwordNotSame: "New password must be different from the current password",
    passwordSpecialChars: "Password must contain special characters: ~!@#$%^&*",
    passwordUpperCase: "Password must contain Upper Case Alphabets (A-Z)",
    passwordLowerCase: "Password must contain Lower Case Alphabets (a-z)",
    passwordNumber: "Password must contain at least one number",
    passwordConfirmedReq: "The confirm password field is required.",
    emptyMessageAlert: "Please enter your appreciation message.",
    passwordContains:
      " Passwords must contain characters from the following categories: lowercase characters (a through z), uppercase characters (A through Z), numbers (0 through 9), and special characters (e.g. ~!@#$%^&*)",
    faTitle: "TWO- STEP AUTHENTICATION",
    faContent:
      "Open the two-steps verification app on your mobile device to get your verification code.",
    code: "Enter verification code",
    incorrectCode: "OTP is invalid",
    codeErr: "Please try again later",
    continue: "CONTINUE",
    validateCode: "Please enter a 6-digit number",
    settingTitle: "Set up multifactor authentication",
    settingContentfa1:
      "Your company requires multifactor authentication to add an additional layer of security when signing in to your Azure account",
    microsoftAuthenticator: "Microsoft Authenticator",
    setUp: "Setup",
    remove: "Remove",
    titleRemovePopup: "Remove Microsoft Authenticator",
    contentRemovePopup:
      "Are you sure you want to remove Microsoft Authenticator enrollment?",
    errRemove2fa: "Currently cannot be removed",
    dowload: "Download Microsoft Authenticator",
    install:
      "Please select your device type and download/install Microsoft Authenticator on your mobile device.",
    back: "Back",
    next: "Next",
    done: "Done",
    step1: "1. Scan the QR code",
    contentStep1:
      "Launch Microsoft Authenticator, tap the '+' icon, then select 'Scan barcode'.",
    notScan: "Can't scan it?",
    notScanTitle: "Can't scan QR Code?",
    notScanContent:
      "Enter the following account name and secret key into your authenticator app.",
    account: "Account:",
    secret: "Secret key:",
    step2: "2. Enter the verification code (OTP)",
    contentStep2: "Please enter the 6-digit code displayed in the application.",
    verifyCode: "Verification code (OTP):",
    settingDoneContent:
      "You have successfully enrolled in Microsoft Authenticator. Your two-factor verification will be required on the next login",
    yes: "Yes",
    no: "No",
    ok: "Ok",
    loginWithOkta: "PSA Staff with Azure",
    loginWithEmail: "Non-Azure User",
    pleaseMethodLogin: "Please choose your login method",
    titleSetting2faPopup: "Setting 2-factor authentication",
    contentSetting2faPopup:
      "Due to our company requirements, multifactor authentication is mandatory. Your account currently does not have 2-factor authentication, so please assist in setting it up.",
    setting: "Setting",
    contentSetupAuthenticator: "Enter single-use code from the mobile app.",
    selectDevice: "Select your device type",
  },
  id: {
    selectlanguage: "Pilih Bahasa",
    email: "EMAIL ID",
    rememberme: "Ingat akun saya",
    password: "KATA SANDI",
    login: "MASUK",
    terms:
      'Dengan mengklik "Masuk" Anda telah menyetujui Syarat dan Ketentuan penggunaan.',
    date: "Tanggal",
    sentby: "Dari",
    fishnumber: "Fishes",
    message: "Pesan",
    sentto: "Kepada",
    logout: "Keluar",
    forgetpassword: "Lupa Kata sandi?",
    forgetpasswordalert: "cek kembali email dan kata sandi anda",
    FAQs: "FAQs",
    send_fish_to: "Kirim Fish kepada",
    fish_news: "Berita Fish",
    fish_sent: " FISH anda berhasil dikirim, Kepada {toEmail}",
    warning:
      "PERINGATAN: Akses informasi tentang layanan dan jaringan ini terbatas hanya untuk personel atau pihak yang berwenang. Setiap penggunaan yang tidak sah akan dikenakan tindak pidana kriminal Computer Misuse Act.",
    your_message: "PESAN ANDA:",
    to: "Kepada:",
    cc: "CC:",
    send: "Kirim",
    from: "Dari",
    incorrectPW: "Alamat e-mail atau kata sandi salah",
    tempPW: "Pasword sementara",
    newPW: "Kata sandi baru",
    confirmPW: "Konfirmasi kata sandi",
    submit: "Kirim",
    currentPW: "Kata sandi saat ini",
    changePW: " Ubah Kata Sandi",
    passwordTempReq: "Kata sandi sementara diperlukan",
    passwordMinChar: "Kata sandi harus minimal 8 karakter",
    passwordReq: "Kata sandi baru",
    passwordMatch: "Kata sandi yang anda masukkan tidak sesuai",
    passwordNotSame:
      "Kata sandi tidak boleh sama dengan 3 kata sandi sebelumnya",
    passwordSpecialChars:
      'Karakteristik bukan alfabet seperti  ~!@#$%^&*_-+=`|(){}[]:;"<>,.?/',
    passwordUpperCase: "Huruf besar (A sampai Z)",
    passwordLowerCase: "Huruf kecil (a sampai z)",
    passwordNumber: "Angka ( 0 sampai 9)",
    passwordConfirmedReq: "Bidang konfirmasi kata sandi wajib diisi",
    emptyMessageAlert: "Silakan masukkan pesan penghargaan Anda.",
    passwordContains:
      'Kata sandi harus mengandung karakter dari tiga kategori berikut: Huruf kecil (a sampai z)\n Huruf besar (A sampai Z)\n Angka (0 sampai 9)\n Karakter bukan alfabet seperti ~!@#$%^&*_-+=`|(){}[]:;"<>,.?/',
    faTitle: "OTENTIKASI DUA LANGKAH",
    faContent:
      "Buka aplikasi verifikasi dua langkah yang ada di perangkat seluler Anda untuk mendapatkan kode verifikasi",
    code: "Masukkan kode verifikasi",
    incorrectCode: "OTP tidak valid",
    continue: "Lanjutkan",
    validateCode: "Silakan masukkan 6 digit nomor",
    settingTitle: "Siapkan autentikasi multifaktor",
    settingContentfa1:
      "Perusahaan memerlukan autentikasi multifaktor untuk menambahkan dan memperketat keamanan tambahan saat masuk ke akun Azure Anda",
    microsoftAuthenticator: "Microsoft Authenticator",
    setUp: "Mempersiapkan",
    remove: "Remove",
    titleRemovePopup: "Hapus Microsoft Authenticator",
    contentRemovePopup:
      "Apakah Anda yakin ingin menghapus pendaftaran Microsoft Authenticator?",
    errRemove2fa: "Saat ini tidak dapat dihapus",
    dowload: "Unduh Microsoft Authenticator",
    install:
      "Silakan pilih jenis perangkat Anda dan unduh/instal Microsoft Authenticator di perangkat seluler Anda.",
    next: "Selanjutnya",
    done: "Selesai",
    step1: "1. Pindai kode QR",
    contentStep1:
      "Meluncurkan Microsoft Authenticator, ketuk ikon '+', lalu pilih 'Pindai kode yang tertera'.",
    notScan: "Gagal memindai?",
    notScanTitle: "Gagal memindai Kode QR?",
    notScanContent:
      "Masukkan nama akun dan kode rahasia berikut ini ke dalam aplikasi autentikator Anda.",
    account: "Akun:",
    secret: "Kode rahasia:",
    step2: "2. Masukkan kode verifikasi (OTP)",
    contentStep2: "Silakan masukkan kode 6 digit yang ditampilkan di aplikasi.",
    verifyCode: "Verifikasi kode (OTP):",
    settingDoneContent:
      "Anda telah berhasil mendaftar di Microsoft Authenticator. Verifikasi dua faktor Anda akan diperlukan pada login berikutnya",
    yes: "Ya",
    no: "Tidak",
    ok: "Ok",
    loginWithOkta: "PSA Staff dengan Azure",
    loginWithEmail: "Non-Azure User",
    pleaseMethodLogin: "Pilih metode login Anda",
    titleSetting2faPopup: "Mengatur otentikasi 2 faktor",
    contentSetting2faPopup:
      "Perusahaan mewajibkan dan mempersyaratkan untuk autentikasi multifaktor. Akun Anda saat ini tidak memiliki autentikasi 2 faktor, Mohon untuk mempersiapkannya terlebih dahulu.",
    setting: "Pengaturan",
    contentSetupAuthenticator:
      "Masukkan kode sekali pakai dari aplikasi seluler.",
    selectDevice: "Pilih jenis perangkat Anda",
  },
  zh: {
    selectlanguage: "选择语言",
    email: "电邮 用户名（ID）",
    rememberme: "记住我",
    password: "密码",
    login: "登录",
    terms: "点击‘登录’表示同意使用条款和条件",
    date: "日期",
    sentby: "发鱼人",
    fishnumber: "鱼",
    message: "信息",
    sentto: "收鱼人",
    logout: "登出",
    forgetpassword: "忘记密码？",
    forgetpasswordalert: "请查收您的邮件获取密码",
    FAQs: "常见问题",
    send_fish_to: "发鱼给",
    fish_news: "鱼情",
    fish_sent: " 发鱼成功, 收鱼人 {toEmail}",
    warning:
      " 警告：访问此服务和网络信息仅限于授权人员。任何未经授权的使用将受到依据计算机滥用法和网络安全法的刑事起诉。",
    your_message: "您的信息:",
    to: "收鱼人:",
    cc: "抄送:",
    send: "发送",
    from: "发鱼人",
    incorrectPW: "您输入的当前密码不正确",
    tempPW: "临时密码",
    newPW: "新密码",
    confirmPW: "确认密码",
    submit: "提交",
    currentPW: "当前密码",
    changePW: " 更换密码",
    passwordTempReq: "需要临时密码",
    passwordMinChar: "密码必须有至少8位字符",
    passwordReq: "需要新密码",
    passwordMatch: "您输入的密码不匹配",
    passwordNotSame: "密码不能与之前3次密码重复",
    passwordSpecialChars:
      '密码必须包含 非字母数字字符，例如：~!@#$%^&*_-+,`|(){}[]:;"<>,.?/',
    passwordUpperCase: "密码必须包含 大写字母（A到Z）",
    passwordLowerCase: "密码必须包含 小写字母（a到z）",
    passwordNumber: "密码必须包含数字（0到9）",
    passwordConfirmedReq: "需要确认密码",
    emptyMessageAlert: "请输入您的感谢信息",
    passwordContains:
      '密码必须包含任何三个以下类别的字符：\n 小写字母（a到z）\n 大写字母（A到Z）\n 数字（0到9）\n 非字母数字字符，例如：~!@#$%^&*_-+=`|(){}[]:;"<>,.?/',
    faTitle: "两步验",
    faContent: "在手机点按上两步验证应用程序以获取您的验证",
    code: "输入验证码",
    incorrectCode: "验证码无效",
    continue: "继续",
    validateCode: "请输入 6 位数",
    settingTitle: "设置用于多重身份验证",
    settingContentfa1:
      "您的公司要求在登录到您的 Azure 帐户时添加额外的安全层时使用多因素身份验证",
    microsoftAuthenticator: "Microsoft Authenticator应用",
    setUp: "设置",
    remove: "Remove",
    titleRemovePopup: "移除 Microsoft Authenticator应用",
    contentRemovePopup: "您确定要移除 Microsoft Authenticator 应用吗？",
    errRemove2fa: "当前无法移除",
    dowload: "下载 Microsoft Authenticator",
    install: "请选择您的设备类型，并在手机上下载/安装 Microsoft",
    next: "下一个",
    done: "完成",
    step1: "扫描二维码",
    contentStep1:
      "启动 Microsoft Authenticator，点击“+” 图标，然后选择“扫描条形码”",
    notScan: "无法扫描？",
    notScanTitle: "无法扫描二维码？",
    notScanContent: "请将以下帐户名称和秘钥输入到您的验证器应用中。",
    account: "账户",
    secret: "秘钥",
    step2: "请输入一次性验证码",
    contentStep2: "请输入在应用程序中显示的 6 位数验证码",
    verifyCode: "一次性验证码",
    settingDoneContent: "请输入在应用程序中显示的 6 位数验证码",
    yes: "是",
    no: "不",
    ok: "确定",
    loginWithOkta: "PSA 员工有 Azure用户",
    loginWithEmail: "非Azure用户",
    pleaseMethodLogin: "请选择您的登录方式",
    titleSetting2faPopup: "设置双因素身份验证",
    contentSetting2faPopup:
      "由于公司规定，多种身份验证是强制性的。 您的帐户当前没有双因素身份验证，请协助设置。",
    setting: "设置",
    contentSetupAuthenticator: "从应用程序中输入一次性验证码",
    selectDevice: "选择您的设备类型",
  },
  nl: {
    selectlanguage: "Taal selecteren",
    email: "E-MAIL GEBRUIKERSNAAM ",
    rememberme: "Onthoud me",
    password: "WACHTWOORD",
    login: "LOG AAN",
    terms: "Door 'log aan' te klikken, ga je akkoord met de voorwaarden",
    date: "Datum",
    sentby: "Van",
    fishnumber: "Vis",
    message: "Bericht",
    sentto: "Naar",
    logout: "LOGOUT",
    forgetpassword: "Wachtwoord vergeten?",
    forgetpasswordalert: "Controleer uw e-mail voor uw wachtwoord",
    FAQs: "FAQs",
    send_fish_to: "Stuur Vis naar",
    fish_news: "Vis Nieuwtjes",
    fish_sent: " Vis met succes verzonden, Naar  {toEmail}",
    warning:
      "WAARSCHUWING: Toegang tot informatie over deze dienst en het netwerk is enkel beperkt tot bevoegd personeel. Elk onbevoegd gebruik is onderworpen aan strafrechtelijke vervolging.",

    your_message: "Jouw bericht:",
    to: "Naar:",
    cc: "CC:",
    send: "Verzend",
    from: "Van",
    incorrectPW: "Huidig ingevoerde wachtwoord is ongeldig.",
    tempPW: "Tijdelijk wachtwoord",
    newPW: "Nieuw wachtwoord",
    confirmPW: "Bevestig wachtwoord",
    submit: "Indienen",
    currentPW: "Huidig wachtwoord",
    changePW: " Verander Wachtwoord",
    passwordTempReq: "Tijdelijk wachtwoord is vereist.",
    passwordMinChar: "Het wachtwoord moet minsten 8 karakters bevatten.",
    passwordReq: "Nieuw wachtwoord.",
    passwordMatch: "Voer nogmaals nieuw wachtwoord in.",
    passwordNotSame:
      "Het wachtwoord mag niet hetzelfde zijn als de vorige 3 wachtwoorden",
    passwordSpecialChars:
      'Niet alfabetische karakters, zoals  ~!@#$%^&*_-+=`|(){}[]:;"<>,.?/',
    passwordUpperCase: "Hoofdletters (A tot Z)",
    passwordLowerCase: "Kleine letters (a tot z)",
    passwordNumber: "Nummers (0 tot 9)",
    passwordConfirmedReq: "Bevestig wachtwoord.",
    emptyMessageAlert: "Voer uw waarderingsbericht in.",
    passwordContains:
      "Het wachtwoord moet karakters bevatten uit de opgegeven drie categorieën:\nKleine letters  (a tot z)\nHoofdletters (A tot Z)\nNummers (0 tot 9)\nNiet alfabetische karakters , e.g. ~!@#$%^&*",
    faTitle: "TWO-STEP AUTHENTICATION",
    faContent:
      "Open de twee-stappen authenticatie-app op je mobiele apparaat om je verificatiecode te verkrijgen.",
    code: "Voer de verificatiecode in",
    incorrectCode: "OTP is ongeldig",
    continue: "GA DOOR",
    validateCode: "Voer alstublieft een 6-cijferige code in",
    settingTitle: "Multifactor-authenticatie instellen",
    settingContentfa1:
      "Uw bedrijf vereist multifactor-authenticatie om een extra beveiligingslaag toe te voegen bij het aanmelden bij uw Azure-account.",
    microsoftAuthenticator: "Microsoft Authenticator",
    setUp: "Instellen",
    remove: "Verwijderen",
    titleRemovePopup: "Microsoft Authenticator verwijderen",
    contentRemovePopup:
      "Weet u zeker dat u de inschrijving van Microsoft Authenticator wilt verwijderen?",
    errRemove2fa: "Kan momenteel niet worden verwijderd",
    dowload: "Download Microsoft Authenticator",
    install:
      "Selecteer uw apparaattype en download/installeer Microsoft Authenticator op uw mobiele apparaat.",
    next: "Volgende",
    done: "Klaar",
    step1: "1. Scan de QR-code",
    contentStep1:
      "Start Microsoft Authenticator, tik op het '+'-pictogram en selecteer vervolgens 'Scan barcode'.",
    notScan: "Kan het niet scannen?",
    notScanTitle: "Kan de QR-code niet scannen?",
    notScanContent:
      "Voer de volgende accountnaam en geheime sleutel in in uw authenticator-app.",
    account: "Account:",
    secret: "Geheime sleutel:",
    step2: "2. Voer de verificatiecode (OTP) in",
    contentStep2:
      "Voer alstublieft de 6-cijferige code in die in de applicatie wordt weergegeven.",
    verifyCode: "Verificatiecode (OTP):",
    settingDoneContent:
      "U hebt succesvol de Microsoft Authenticator geactiveerd. Uw tweestapsverificatie is vereist bij de volgende aanmelding.",
    yes: "Ja",
    no: "Nee",
    ok: "Oké",
    loginWithOkta: "PSA-medewerker met Azure",
    loginWithEmail: "Niet-Azure-gebruiker",
    pleaseMethodLogin: "Kies alstublieft uw aanmeldingsmethode",
    titleSetting2faPopup: "2-factor-authenticatie instellen",
    contentSetting2faPopup:
      "Vanwege de vereisten van ons bedrijf is multifactor-authenticatie verplicht. Uw account heeft momenteel geen 2-factor-authenticatie, dus help ons alstublieft bij het instellen.",
    setting: "Instelling",
    contentSetupAuthenticator: "Voer de eenmalige code in van de mobiele app.",
    selectDevice: "Selecteer uw apparaattype",
  },
  it: {
    selectlanguage: "Seleziona lingua",
    email: "EMAIL ID",
    rememberme: "Ricordati di me",
    password: "PASSWORD",
    login: "Accedi",
    terms: `Facendo clic su "Accedi" hai accettato i Termini e Condizioni d'uso.`,
    date: "Data",
    sentby: "Da",
    fishnumber: "N° di Fish",
    message: "Messaggio",
    sentto: "A",
    logout: "Esci",
    forgetpassword: "Hai dimenticato la password?",
    forgetpasswordalert:
      "Per favore controlla la tua email per trovare la password",
    FAQs: "FAQs",
    send_fish_to: "Invia Fish a",
    fish_news: "Notizie di Fish",
    fish_sent: " Invio riuscito di Fish a {toEmail}",
    warning:
      "AVVERTENZA: l'accesso alle informazioni su questo servizio e sulla rete è limitato al solo personale autorizzato. Qualsiasi utilizzo non autorizzato è soggetto a procedimento penale.",

    your_message: "Il tuo messaggio:",
    to: "A:",
    cc: "CC:",
    send: "Invia",
    from: "DA",
    incorrectPW: "La password attuale inserita non è valida.",
    tempPW: "Password temporanea",
    newPW: "Nuova Password",
    confirmPW: "Conferma Password",
    submit: "Invia",
    currentPW: "Password Attuale",
    changePW: " Cambia La Password",
    passwordTempReq: "È richiesta una password temporanea",
    passwordMinChar: "La password deve contenere almeno 8 caratteri.",
    passwordReq: "È richiesta una Nuova Password",
    passwordMatch: "le password che hai inserito non corrispondevan",
    passwordNotSame:
      "La password non deve essere uguale alle precedenti 3 password.",
    passwordSpecialChars:
      "Caratteri non alfanumerici, ad es. ~!@#$%^&*_-+='|(){}[]:;\"<>,.?/",
    passwordUpperCase: "Caratteri maiuscoli (dalla A alla Z)",
    passwordLowerCase: "Caratteri minuscoli (dalla a alla z)",
    passwordNumber: "Numeri (da 0 a 9)",
    passwordConfirmedReq: "Conferma Password.",
    emptyMessageAlert: "Inserisci il tuo messaggio di apprezzamento.",
    passwordContains:
      "Le password devono contenere caratteri di tre delle seguenti categorie:\n Caratteri minuscoli (dalla a alla z)\n Caratteri maiuscoli (dalla A alla Z)\n Numeri (da 0 a 9)\n Caratteri non alfanumerici, ad es.~!@#$%^&*",
    faTitle: "DOPPIA AUTENTICAZIONE",
    faContent:
      "Apri l’applicazione di verifica in due passaggi sul tuo dispositivo mobile per ottenere il codice di verifica.",
    code: "Digita il codice di verifica",
    incorrectCode: "OTP valido",
    continue: "CONTINUA",
    validateCode: "Inserisci il numero di 6 cifre",
    settingTitle: "Imposta l’autenticazione a più fattori",
    settingContentfa1:
      "La tua azienda richiede l'autenticazione a più fattori per aggiungere un ulteriore livello di sicurezza quando accedi al tuo account Azure",
    microsoftAuthenticator: "Microsoft Authenticator",
    setUp: "Configurazione",
    remove: "Remove",
    titleRemovePopup: "Rimuovere Microsoft Authenticator",
    contentRemovePopup:
      "Sei sicuro di voler rimuovere Microsoft Authenticator?",
    errRemove2fa: "Attualmente non può essere rimosso",
    dowload: "Scarica Microsoft Authenticator",
    install:
      "Selezionare il tipo di dispositivo e scarica/installa Microsoft Authenticator",
    next: "Prossimo",
    done: "Fatto",
    step1: "1. Scannerizza il QR Code",
    contentStep1:
      "Lancia Microsoft Authenticator, clicca sull'icona '+', e poi seleziona 'Scannerizza barcode'",
    notScan: "Non puoi scannerizzare?",
    notScanTitle: "Non puoi scannerizzare il QR Code?",
    notScanContent:
      "Inserisci il seguente nome utente e password nella tua App di autenticazione",
    account: "Utente:",
    secret: "Secret key:",
    step2: "2. Inserisci il codice di verifica (OTP)",
    contentStep2:
      "Inserisci il codice di 6 cifre visualizzato nell'applicazione",
    verifyCode: "Codice di verifica (OTP):",
    settingDoneContent:
      "Ha correttamente effettuato la registrazione a Microsoft Authenticator. La verifica a due fattori sarà richiesta al prossimo accesso",
    yes: "Si",
    no: "No",
    ok: "Ok",
    loginWithOkta: "Utenti PSA con Azure",
    loginWithEmail: "Utenti non Azure",
    pleaseMethodLogin: "Scegli il tuo metodo di accesso",
    titleSetting2faPopup: "Impostazione dell'autentificazione a 2 fattori",
    contentSetting2faPopup:
      "A causa dei requisiti aziendali, l'autenticazione multifattore è obbligatoria. Il tuo utente attualmente non dispone di autenticazione a 2 fattori, quindi, procedi con la configurazione.",
    setting: "Configurazione",
    contentSetupAuthenticator: "Inserisci il codice monouso dall'App Mobile.",
    selectDevice: "Seleziona il tipo di dispositivo",
  },
  ko: {
    selectlanguage: "언어 선택",
    email: "ID(이메일)",
    rememberme: "ID 기억하기",
    password: "비밀번호",
    login: "로그인",
    terms: '로그인"을 클릭하면 이용 약관에 동의하는 것입니다."',
    date: "날짜",
    sentby: "발신자",
    fishnumber: "Fishes",
    message: "메시지",
    sentto: "수신자",
    logout: "로그 아웃",
    forgetpassword: "비밀번호를 잊으셨습니까?",
    forgetpasswordalert: "비밀번호 관련하여 이메일을 확인하십시오.",
    FAQs: "FAQs",
    send_fish_to: "Fish 보내기",
    fish_news: "Fish 뉴스",
    fish_sent: " FISH 전송을 성공하였습니다, 수신자 {toEmail}",
    warning:
      "경고: 권한이 부여된 사람에 한하여 이 서비스 및 네트워크 정보에접근을 허용합니다. 무단 사용시 정보 보호 및 보안 관련 법에 따라 형사 처벌을 받을 수 있습니다.",
    your_message: "귀하의 메시지:",
    to: "수신자:",
    cc: "참조자:",
    send: "보내기",
    from: "발신자",
    incorrectPW: "입력한 비밀번호가 유효하지 않습니다.",
    tempPW: "임시 비밀번호",
    newPW: "새 비밀번호",
    confirmPW: "비밀번호 확인",
    submit: "제출하다",
    currentPW: "현재 비밀번호",
    changePW: " 비밀번호 변경",
    passwordTempReq: "임시 비밀번호가 필요합니다",
    passwordMinChar: "비밀번호는 최소 8자리 이상이어야 합니다.",
    passwordReq: "새 비밀번호가 필요합니다",
    passwordMatch: "입력한 비밀번호가 일치하지 않습니다.",
    passwordNotSame: "최근 사용한 3개의 비밀번호는 사용할 수 없습니다.",
    passwordSpecialChars: '특수문자 (예) ~!@#$%^&*_-+=`|(){}[]:;"<>,.?/',
    passwordUpperCase: "영문 대문자 (A~Z)",
    passwordLowerCase: "영문 소문자 (a~z)",
    passwordNumber: "숫자 (0~9)",
    passwordConfirmedReq: "비밀번호 확인 필드가 필요합니다.",
    emptyMessageAlert: "감사의 메시지를 입력해주세요.",
    passwordContains:
      '비밀번호는 다음 중 3가지 종류의 문자를 포함해야 합니다.: ~!@#$%^&*_-+=`|(){}[]:;"<>,.?/',
    faTitle: "2단계 인증을 설정합니다.",
    faContent: "모바일 디바이스에서 2단계 인증 앱을 열어 인증코드를 받으세요.",
    code: "인증코드를 입력하세요",
    incorrectCode: "OTP가 유효하지 않지 않습니다.",
    continue: "계속",
    validateCode: "6자리 숫자를 입력하세요.",
    settingTitle: "2단계 인증을 설정합니다.",
    settingContentfa1:
      "회사에서 Azure 계정에 로그인할 때 추가 보안을 추가하기 위해 2단계 인증이 필요합니다.",
    microsoftAuthenticator: "Microsoft 인증",
    setUp: "설정하기",
    remove: "제거합니다",
    titleRemovePopup: "Microsoft Authenticator을 제거합니다.",
    contentRemovePopup: "Microsoft Authenticator 등록을 제거하시겠습니까?",
    errRemove2fa: "현재 제거할 수 없습니다.",
    dowload: "Microsoft Authenticator를 다운로드합니다",
    install:
      "디바이스 유형을 선택하고 모바일 디바이스에 Microsoft Authenticator를 다운로드/설치하세요",
    next: "다음",
    done: "완료",
    step1: "1. QR 코드를 스캔합니다",
    contentStep1:
      "Microsoft Authenticator를 시작하고 '+' 아이콘을 탭한 다음 '바코드 스캔'을 선택합니다",
    notScan: "스캔할 수 없나요?",
    notScanTitle: "QR 코드를 스캔할 수 없나요?",
    notScanContent: "인증앱에 다음 계정 이름과 비밀 키를 입력합니다",
    account: "계정:",
    secret: "비밀 키:",
    step2: "2. 인증 코드(OTP)를 입력합니다",
    contentStep2: "앱에 표시된 6자리 코드를 입력하세요",
    verifyCode: "인증 코드(OTP):",
    settingDoneContent:
      "Microsoft Authenticator에 성공적으로 등록했습니다. 다음 로그인 시 2단계 인증이 필요합니다",
    yes: "예",
    no: "아니요",
    ok: "Ok",
    loginWithOkta: "Azure를 사용하는 PSA 직원",
    loginWithEmail: "Azure 미사용자",
    pleaseMethodLogin: "로그인 방법을 선택해 주세요",
    titleSetting2faPopup: "2단계 인증 설정하기",
    contentSetting2faPopup:
      "회사 요구 사항으로 인해 다중 인증이 필수입니다. 현재 계정에 2단계 인증이 설정되어 있지 않으므로 설정을 도와주세요",
    setting: "설정하기",
    contentSetupAuthenticator: "모바일 앱에서 일회용 코드를 입력하세요...",
    selectDevice: "디바이스 유형을 선택하세요",
  },
  tr: {
    selectlanguage: "Dil Seçin",
    email: "E-POSTA ID",
    rememberme: "Beni Hatırla",
    password: "Şifre",
    login: "GİRİŞ",
    terms:
      '"Giriş"e tıklayarak kullanım Şart ve Koşullarını kabul etmiş bulunuyorsunuz.',
    date: "TARİH ",
    sentby: "Kimden",
    fishnumber: "Balıklar",
    message: "Mesaj",
    sentto: "KİME",
    logout: "Oturumu kapat",
    forgetpassword: "Şifreni Unuttun mu?",
    forgetpasswordalert: "Lütfen e-posta adresinizi ve şifrenizi kontrol edin",
    FAQs: "FAQs",
    send_fish_to: "Birine Gönder",
    fish_news: "Fish Haberler",
    fish_sent: " FISH gönderimi başarılı, Kime {toEmail}",
    warning:
      "UYARI: Bu uygulama ve ağdaki bilgilere erişim yalnızca yetkili personel ile sınırlıdır. İzinsiz kullanıcılar, Bilgisayarın Kötüye Kullanımı Yasası uyarınca cezai yaptırıma tabidir.",
    your_message: "Mesajın:",
    to: "Kime:",
    cc: "Bilgi:",
    send: "Gönder",
    from: "KİMDEN",
    tempPW: "Mevcut Şifre",
    newPW: "Yeni Şifre",
    confirmPW: "Şifreni doğrula",
    submit: "Gönder",
    currentPW: "Mevcut Şifre",
    changePW: " Şifreni Değiştir",
    passwordTempReq: "Temporary Password is required",
    passwordMinChar: "Yeni şifre en az 8 karakter uzunluğunda olmalıdır.",
    passwordReq: "Yeni bir şifre gerekli.",
    passwordMatch: "Şifre eşleşmiyor",
    passwordNotSame: "New password must be different from the current password",
    passwordSpecialChars: "Password must contain special characters: ~!@#$%^&*",
    passwordUpperCase: "Parola Büyük Harf Alfabeleri (A-Z) içermelidir",
    passwordLowerCase: "Şifre Küçük Harf Alfabeleri (a-z) içermelidir",
    passwordNumber: "Numbers (0 through 9)",
    passwordConfirmedReq: "Parolayı onayla alanı gereklidir.",
    emptyMessageAlert: "Lütfen teşekkür mesajınızı giriniz.",
    passwordContains:
      " Şifreler, aşağıdaki kategorilerden üçünden karakter içermelidir:\nKüçük harf karakterler (a'dan z'ye)\nBüyük harf karakterler (A'dan Z'ye)\nRakamlar (0 - 9)\nNoktalama işaretleri ör:  ~!@#$%^&*",
    faTitle: "İKİ ADIMLI KİMLİK DOĞRULAMA",
    faContent:
      "Doğrulama kodunuzu almak için mobil cihazınızda iki adımlı doğrulama uygulamasını açın",
    code: "Doğrulama kodunu girin",
    incorrectCode: "OTP geçersiz",
    continue: "DEVAM",
    validateCode: "Lütfen 6 haneli bir sayı girin",
    settingTitle: "Çok faktörlü kimlik doğrulamayı kurun",
    settingContentfa1: "Çok faktörlü kimlik doğrulamayı kurun",
    microsoftAuthenticator: "Microsoft Authenticator",
    setUp: "Kurulum",
    remove: "Kaldırın",
    titleRemovePopup: "Microsoft Authenticator'ı kaldırın",
    contentRemovePopup:
      "Microsoft Authenticator kaydını kaldırmak istediğinizden emin misiniz?",
    errRemove2fa: "Şu anda kaldırılamaz",
    dowload: "Microsoft Authenticator'ı indirin",
    install:
      "Lütfen cihaz türünüzü seçin ve Microsoft Authenticator'ı mobil cihazınıza indirin/yükleyin",
    next: "İleri",
    done: "Tamamlandı",
    step1: "1.QR kodunu tarayın",
    contentStep1:
      "Microsoft Authenticator'ı başlatın, '+' simgesine dokunun ve ardından 'Barkodu tara'yı seçin",
    notScan: "Tarayamıyor musunuz?",
    notScanTitle: "QR Kod taranamıyor mu?",
    notScanContent:
      "Kimlik doğrulayıcı uygulamanıza aşağıdaki hesap adını ve gizli anahtarı girin.",
    account: "Hesap:",
    secret: "Gizli anahtar:",
    step2: "2. Doğrulama kodunu (OTP) girin",
    contentStep2: "Lütfen uygulamada görüntülenen 6 haneli kodu giriniz.",
    verifyCode: "Doğrulama kodu (OTP):",
    settingDoneContent:
      "Microsoft Authenticator'a başarıyla kaydoldunuz. Bir sonraki oturum açma işleminizde iki faktörlü doğrulamanız gerekecektir",
    yes: "Evet",
    no: "Hayır",
    ok: "Tamam",
    loginWithOkta: "Azure Kullanıcısı",
    loginWithEmail: "Azure Kullanıcısı Olmayan",
    pleaseMethodLogin: "Lütfen giriş yönteminizi seçin",
    titleSetting2faPopup: "2 faktörlü kimlik doğrulamayı ayarlama",
    contentSetting2faPopup:
      "Şirket gereksinimlerimiz nedeniyle, çok faktörlü kimlik doğrulama zorunludur. Hesabınızda şu anda 2 faktörlü kimlik doğrulama bulunmuyor, bu nedenle lütfen kurulumuna yardımcı olun.",
    setting: "Ayarlar",
    contentSetupAuthenticator: "Mobil uygulamadan tek kullanımlık kodu girin.",
    selectDevice: "Cihaz türünüzü seçin",
  },
  ar: {
    selectlanguage: "اختر اللغة",
    email: "البريد إلكتروني",
    rememberme: "تذكرنى",
    password: "كلمة المرور",
    login: "تسجيل الدخول",
    terms:
      'بالنقر على "تسجيل الدخول" ، تكون قد وافقت على شروط وأحكام الاستخدام.',
    date: " تاريخ الارسال ",
    sentby: "من",
    fishnumber: "عدد الأسماك",
    message: "رسالة",
    sentto: "إلى",
    logout: "تسجيل خروج",
    forgetpassword: "هل نسيت كلمة السر؟",
    forgetpasswordalert:
      "يرجى التحقق من بريدك الإلكتروني للحصول على كلمة المرور",
    FAQs: "أسئلة وأجوبة",
    send_fish_to: "أرسل السمك",
    fish_news: "أخبار الأسماك",
    fish_sent: " {toEmail}تم إرسال الأسماك بنجاح إلى ",
    warning:
      "تحذير: يقتصر الوصول إلى المعلومات الموجودة على هذا الجهاز والشبكة على الأفراد المصرح لهم فقط. يخضع أي مستخدم غير مصرح له للمحاكمة الجنائية بموجب قانون إساءة استخدام الكمبيوتر والأمن السيبراني.",
    your_message: "رسالة",
    to: "إلى",
    cc: "نسخة",
    send: "أرسل",
    from: "من:",
    incorrectPW: "خطأ في إدخال العنوان البريدي أو كلمة السر",
    tempPW: "كلمة مرور مؤقتة",
    newPW: "كلمة المرور الجديدة",
    confirmPW: "تأكيد كلمة المرور",
    submit: "يُقدِّم",
    currentPW: "كلمة السر الحالية",
    changePW: "  تغيير كلمة المرور",
    passwordTempReq: "كلمة المرور المؤقتة مطلوبة",
    passwordMinChar: "يجب أن تتكون كلمة المرور الجديدة من 8 أحرف على الأقل",
    passwordReq: "مطلوب كلمة مرور جديدة",
    passwordMatch: "يجب أن تتطابق كلمات المرور",
    passwordNotSame:
      "يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمة المرور الحالية",
    passwordSpecialChars:
      "يجب أن تحتوي كلمة المرور على أحرف خاصة: ~! @ # $٪ ^ & *",
    passwordUpperCase: "يجب أن تحتوي كلمة المرور على أحرف أبجدية كبيرة (A-Z)",
    passwordLowerCase: "يجب أن تحتوي كلمة المرور على أحرف أبجدية صغيرة (a-z) ",
    passwordNumber: "يجب ان تحتوي كلمة المرور على الاقل رقما واحدا",
    passwordConfirmedReq: " مطلوب تأكيد حقل كلمة المرور ",
    emptyMessageAlert: "الرجاء ادخال رسالة الشكر الخاصة بك.",
    passwordContains:
      "يجب أن تحتوي كلمات المرور على أحرف من الفئات التالية: أحرف صغيرة (أ إلى ي) ، وأحرف كبيرة (أ إلى ي) ، وأرقام (من 0 إلى 9) ، وأحرف خاصة (مثل ~! @ # $٪ ^ & *)",
    faTitle: "المصادقة الثنائية",
    faContent: "افتح تطبيق على جهازك المحمول للحصول على رمز التحقق الخاص بك.",
    code: "أدخل رمز التحقق،",
    incorrectCode: "رمز التحقق غير صالح",
    continue: "المتابعة",
    validateCode: "الرجاء إدخال رقم مكون من 6 خانات،",
    settingTitle: "إعدادات المصادقة",
    settingContentfa1:
      "نظرًا لمتطلبات شركتنا، تعد المصادقة الثنائية إلزامية لأغراض الحماية عند تسجيل الدخول إلى حساب Azure الخاص بك،",
    microsoftAuthenticator: "أداة المصادقة في مايكروسوفت,",
    setUp: "تثبيت",
    remove: "إزالة أداة مصادقة الميكروسوفت،",
    titleRemovePopup: "إزالة أداة مصادقة الميكروسوفت،",
    contentRemovePopup: "هل أنت متأكد أنك تريد إزالة  أداة مصادقة الميكروسوفت",
    errRemove2fa: " لايمكن إزالتها حاليا ",
    dowload: "تحميل برنامج المصادقة على المايكروسوفت,",
    install:
      "يرجى تحديد نوع جهازك وتنزيل/تثبيت أداة المصادقة على جهازك المحمول.",
    next: "التالي،",
    done: "انتهى",
    step1: " قم بمسح رمز الاستجابة السريعة ،",
    contentStep1:
      'قم بتشغيل أداة المصادقه، وانقر فوق أيقونة "+"، ثم حدد "مسح رمز الاستجاية السريعة".',
    notScan: "لا يمكنك المسح ؟",
    notScanTitle: "لا يمكنك مسح رمز الاستجابة السريعة؟",
    notScanContent:
      "أدخل اسم الحساب التالي والرمز  السري في تطبيق المصادقة الخاص بك.",
    account: "حساب",
    secret: "الرمز السري",
    step2: "أدخل رمز التحقق  OTP",
    contentStep2: "الرجاء إدخال الرمز المكون من 6 أرقام الظاهر في التطبيق.,",
    verifyCode: "رمز التحقق (OTP):،",
    settingDoneContent:
      "لقد قمت بالتسجيل بنجاح في أداة المصادقه على الميكروسوفت. سيكون التحقق الثنائي مطلوبًا عند تسجيل الدخول مرة أخرى،",
    yes: "نعم،",
    no: "لا،",
    ok: "نعم،",
    loginWithOkta: "طاقم بي.إس.أي  مع Azure،",
    loginWithEmail: "لست مستخدم لدى Azure",
    pleaseMethodLogin: "يرجى اختيار طريقة تسجيل الدخول الخاصة بك،",
    titleSetting2faPopup: "إعداد المصادقة الثنائية،",
    contentSetting2faPopup:
      "نظرًا لمتطلبات شركتنا، تعد المصادقة الثنائية إلزامية. حسابك حاليًا لا يحتوي على مصادقة ثنائية، لذا يرجى المساعدة في إعدادها.,",
    setting: "إعدادات ",
    contentSetupAuthenticator:
      "أدخل رمز الدخول لمرة واحدة  من تطبيق الهاتف المحمول.",
    selectDevice: "حدد نوع جهازك،",
  },
  "es-PA": {
    selectlanguage: "Seleccionar Idioma",
    email: "Identificación de correo",
    rememberme: "Recuerdame",
    password: "Contraseña",
    login: "LOG IN",
    terms:
      'Al hacer clic en "Iniciar sesión", ha aceptado los términos y condiciones de uso.',
    date: "FECHA",
    sentby: "POR",
    fishnumber: "NO. DE FISH (NÚMERO DE FISH)",
    message: "MENSAJE",
    sentto: "A",
    logout: "CERRAR SESIÓN",
    forgetpassword: "¿HAS OLVIDADO TU CONTRASEÑA?",
    forgetpasswordalert:
      "Por favor, chequee su correo electrónico para ver la contraseña",
    FAQs: "Preguntas frecuentes",
    send_fish_to: "Enviado a",
    fish_news: "NOTICIAS DE FISH",
    fish_sent: "¡Tu mensaje FISH se ha enviado correctamente a {toEmail}!",
    warning:
      "ADVERTENCIA: El acceso a la información sobre este servicio y la red está restringido solo al personal autorizado. Cualquier uso no autorizado está sujeto a enjuiciamiento penal bajo la Ley de Uso Informe de Computador y la Ley de Ciberseguridad (CAP 50A).",
    your_message: "SU MENSAJE AQUÍ",
    to: "A:",
    cc: "CC:",
    send: "Enviado",
    from: "DE",
    incorrectPW: "Dirección de correo electrónico o contraseña no válidos",
    tempPW: "Contraseña Temporal",
    newPW: "Nueva contraseña",
    confirmPW: "Confirmar contraseña",
    submit: "Enviado a",
    currentPW: "Contraseña actual",
    changePW: "CAMBIE LA CONTRASEÑA",
    passwordTempReq: "Se requiere una Contraseña Temporal",
    passwordMinChar: "La contraseña debe ser mínimo de 8 caracteres.",
    passwordReq: "Ingrese su nueva contraseña",
    passwordMatch: "Las contraseñas que ingresó no coincidían.",
    passwordNotSame:
      "La nueva contraseña debe ser diferente a la contraseña actual",
    passwordSpecialChars:
      "La contraseña debe contener caracteres especiales: ~!@#$%^&",
    passwordUpperCase: "La contraseña debe contener letras mayúsculas (A-Z)",
    passwordLowerCase: "La contraseña debe contener letras minúsculas (a-z)",
    passwordNumber: "La contraseña debe contener al menos un número",
    passwordConfirmedReq:
      "El campo de confirmación de contraseña es obligatorio.",
    emptyMessageAlert: "Por favor, ingresa tu mensaje de agradecimiento.",
    passwordContains:
      "Las contraseñas deben contener caracteres de las siguientes categorías: letras minúsculas (a a z), letras mayúsculas (A a Z), números (0 a 9) y caracteres especiales (por ejemplo, ~!@#$%^&)",
    faTitle: "AUTENTICACIÓN DE DOS PASOS",
    faContent:
      "Abre la aplicación de verificación de dos pasos en tu dispositivo móvil para obtener tu código de verificación.",
    code: "Ingresa el código de verificación.",
    incorrectCode: "Código inválido.",
    codeErr: "Por favor, inténtalo de nuevo más tarde.",
    continue: "CONTINUAR",
    validateCode: "Por favor, introduce un número de 6 dígitos.",
    settingTitle: "Configurar autenticación multifactorial",
    settingContentfa1:
      "Tu empresa requiere autenticación multifactorial para agregar una capa adicional de seguridad al iniciar sesión en tu cuenta de Azure.",
    microsoftAuthenticator: "Microsoft Authenticator",
    setUp: "Configurar",
    remove: "Eliminar",
    titleRemovePopup: "Eliminar Microsoft Authenticator",
    contentRemovePopup:
      "¿Estás seguro de que quieres eliminar la inscripción de Microsoft Authenticator?",
    errRemove2fa: "Actualmente no se puede eliminar",
    dowload: "Descargar Microsoft Authenticator",
    install:
      "Por favor, selecciona el tipo de tu dispositivo y descarga/instala Microsoft Authenticator en tu dispositivo móvil.",
    next: "Siguiente",
    done: "Hecho",
    step1: "Escanea el código QR",
    contentStep1:
      "Inicia Microsoft Authenticator, toca el icono '+', luego selecciona 'Escanear código de barras'.",
    notScan: "¿No puedes escanearlo?",
    notScanTitle: "¿No puedes escanear el código QR?",
    notScanContent:
      "Ingresa el siguiente nombre de cuenta y clave secreta en tu aplicación autenticadora.",
    account: "Cuenta:",
    secret: "Clave secreta:",
    step2: "Ingresa el código de verificación",
    contentStep2:
      "Por favor, ingresa el código de 6 dígitos que se muestra en la aplicación.",
    verifyCode: "Código de verificación",
    settingDoneContent:
      "Te has inscrito correctamente en Microsoft Authenticator. Se requerirá tu verificación de dos factores en el próximo inicio de sesión",
    yes: "Si ",
    no: "No",
    ok: "Ok",
    loginWithOkta: "Personal de PSA con Azure",
    loginWithEmail: "Usuario sin Azure",
    pleaseMethodLogin: "Por favor, elige tu método de inicio de sesión",
    titleSetting2faPopup: "Configurando autenticación de dos factores",
    contentSetting2faPopup:
      "Debido a los requisitos de nuestra empresa, la autenticación multifactor es obligatoria. Tu cuenta actualmente no tiene autenticación de dos factores, así que por favor ayúdanos a configurarla.",
    setting: "Configuración",
    contentSetupAuthenticator:
      "Ingresa el código de un solo uso de la aplicación móvil.",
    selectDevice: "Selecciona el tipo de tu dispositivo",
  },
  "es-AR": {
    selectlanguage: "Seleccionar Idioma",
    email: "IDENTIFICACIÓN DE CORREO",
    rememberme: "RECUERDAME",
    password: "CONTRASEÑA",
    login: "LOG IN",
    terms:
      'Al hacer clic en "Iniciar sesión", ha aceptado los términos y condiciones de uso.',
    date: "FECHA",
    sentby: "POR",
    fishnumber: "NO. DE FISH (NÚMERO DE FISH)",
    message: "MENSAJE",
    sentto: "A",
    logout: "CERRAR SESIÓN",
    forgetpassword: "¿HAS OLVIDADO TU CONTRASEÑA?",
    forgetpasswordalert:
      "Por favor, revisa tu correo electrónico para la nueva contraseña",
    FAQs: "PREGUNTAS FRECUENTES",
    send_fish_to: "Enviado a",
    fish_news: "NOTICIAS DE FISH",
    fish_sent: "¡Tu mensaje FISH se ha enviado correctamente a{toEmail}!",
    warning:
      "ADVERTENCIA: El acceso a la información sobre este servicio y la red está restringido solo al personal autorizado. Cualquier uso no autorizado está sujeto a enjuiciamiento penal bajo la Ley de Uso Informe de Computador y la Ley de Ciberseguridad (CAP 50A).",
    your_message: "SU MENSAJE AQUÍ",
    to: "A:",
    cc: "CC:",
    send: "Enviado",
    from: "DE",
    incorrectPW: "Dirección de correo electrónico o contraseña no válidos",
    tempPW: "Contraseña Temporal",
    newPW: "Nueva contraseña",
    confirmPW: "Confirmar contraseña",
    submit: "Enviado a",
    currentPW: "Contraseña actual",
    changePW: " Cambie la contraseña",
    passwordTempReq: "Se requiere una Contraseña Temporal",
    passwordMinChar: "La contraseña debe ser mínimo de 8 caracteres.",
    passwordReq: "Ingrese su nueva contraseña.",
    passwordMatch: "Las contraseñas que ingresó no coincidían..",
    passwordNotSame:
      "La nueva contraseña debe ser diferente a la contraseña actual",
    passwordSpecialChars:
      "La contraseña debe contener caracteres especiales: ~!@#$%^&",
    passwordUpperCase: "La contraseña debe contener letras mayúsculas (A-Z)",
    passwordLowerCase: "La contraseña debe contener letras minúsculas (a-z)",
    passwordNumber: "La contraseña debe contener al menos un número",
    passwordConfirmedReq:
      "El campo de confirmación de contraseña es obligatorio.",
    emptyMessageAlert: "Por favor, ingresa tu mensaje de agradecimiento.",
    passwordContains:
      " Las contraseñas deben contener caracteres de las siguientes categorías: letras minúsculas (a a z), letras mayúsculas (A a Z), números (0 a 9) y caracteres especiales (por ejemplo, ~!@#$%^&),",
    faTitle: " AUTENTICACIÓN DE DOS PASOS",
    faContent:
      "Abre la aplicación de verificación de dos pasos en tu dispositivo móvil para obtener tu código de verificación.",
    code: "Ingresa el código de verificación.",
    incorrectCode: "Código inválido.",
    codeErr: "Por favor, inténtalo de nuevo más tarde.",
    continue: "CONTINUAR",
    validateCode: "Por favor, introduce un número de 6 dígitos.",
    settingTitle: "Configurar autenticación multifactorial",
    settingContentfa1:
      "Tu empresa requiere autenticación multifactorial para agregar una capa adicional de seguridad al iniciar sesión en tu cuenta de Azure.",
    microsoftAuthenticator: "Microsoft Authenticator",
    setUp: "Configurar",
    remove: "Eliminar",
    titleRemovePopup: "Eliminar Microsoft Authenticator",
    contentRemovePopup:
      "¿Estás seguro de que quieres eliminar la inscripción de Microsoft Authenticator?",
    errRemove2fa: "Actualmente no se puede eliminar",
    dowload: "Descargar Microsoft Authenticator",
    install:
      "Por favor, selecciona el tipo de tu dispositivo y descarga/instala Microsoft Authenticator en tu dispositivo móvil.",
    next: "Siguiente",
    done: "Hecho",
    step1: "Escanea el código QR",
    contentStep1:
      "Inicia Microsoft Authenticator, toca el icono '+', luego selecciona 'Escanear código de barras'.",
    notScan: "¿No puedes escanearlo?",
    notScanTitle: "¿No puedes escanear el código QR?",
    notScanContent:
      "Ingresa el siguiente nombre de cuenta y clave secreta en tu aplicación autenticadora.",
    account: "Cuenta:",
    secret: "Clave secreta:",
    step2: "Ingresa el código de verificación",
    contentStep2:
      "Por favor, ingresa el código de 6 dígitos que se muestra en la aplicación.",
    verifyCode: "Código de verificación:",
    settingDoneContent:
      "Te has inscrito correctamente en Microsoft Authenticator. Se requerirá tu verificación de dos factores en el próximo inicio de sesión",
    yes: "Si",
    no: "No",
    ok: "Ok",
    loginWithOkta: "Personal de PSA con Azure",
    loginWithEmail: "Usuario sin Azure",
    pleaseMethodLogin: "Por favor, elige tu método de inicio de sesión",
    titleSetting2faPopup: "Configurando autenticación de dos factores",
    contentSetting2faPopup:
      "Debido a los requisitos de nuestra empresa, la autenticación multifactor es obligatoria. Tu cuenta actualmente no tiene autenticación de dos factores, así que por favor ayúdanos a configurarla.",
    setting: "Configuración",
    contentSetupAuthenticator:
      "Ingresa el código de un solo uso de la aplicación móvil.",
    selectDevice: "Selecciona el tipo de tu dispositivo",
  },
};

export default messages;
