import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/esm/Table";
import axios from "axios";
import FishOutItem from "./FishOutItem";
import "./OutBoxFishList.css";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "../Message/messages";
import "./OutBoxFishList.css";

const OutBoxFishList = () => {
  var constants;

  if (process.env.REACT_APP_ENV === 'local') {
    constants = require("../lib/constant-local");
  }
  else if (process.env.REACT_APP_ENV === 'dev') {
    constants = require("../lib/constant-dev");
  }
  else if (process.env.REACT_APP_ENV === 'uat') {
    constants = require("../lib/constant-uat");
  }
  else if (process.env.REACT_APP_ENV === 'prod') {
    constants = require("../lib/constant-prod");
  }
  else {
    constants = require("../lib/constant");
  }
  const baseURL = constants.GETOUTBOX;
  const [fishesOut, setFishesOut] = useState([]);
  const sessionToken = sessionStorage.getItem("sessionToken");
  const [locale, setLocale] = useState("en");
  //console.log(sessionToken);
  const sessionDetails = {
    username: sessionToken,
    orderBy: "DESC",
    id: 1,
    sortBy: "datetime",
    offset: 0,
  };
  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
    axios
      .post(baseURL, sessionDetails)
      .then((res) => {
        setFishesOut(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //console.log(fishesOut);
  return (
    <>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <div style={{ height: "100%", overflow: "auto" }}>
          <Table className="fixed-table">
            <thead className="stickyheader">
              <tr>
                <th className="text-center">
                  <FormattedMessage id="date" />
                </th>
                <th className="text-left">
                  <FormattedMessage id="sentto" />
                </th>
                <th className="text-left">
                  <FormattedMessage id="fishnumber" />
                </th>
                <th className="text-left">
                  <FormattedMessage id="message" />
                </th>
              </tr>
            </thead>

            <tbody className="normalBody">
              {fishesOut.map((fishOut) => (
                <FishOutItem
                  key={fishOut.id}
                  date={fishOut.date}
                  name={fishOut.name}
                  fishcount={fishOut.fishcount}
                  message={fishOut.message}
                />
              ))}
            </tbody>
          </Table>
        </div>
      </IntlProvider>
    </>
  );
};

export default OutBoxFishList;
