import "./FishNavBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Nav } from "react-bootstrap";
import { Navigate, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import inbin from "./inBin.png";
import outbin from "./outBin.png";
import setting from "./setting.svg";
import { Tooltip } from "@mui/material";

const FishNavBar = () => {
  // const onClickHandler = (e) => {
  //   e.preventDefault();
  //   sessionStorage.setItem("login", "out");
  //   console.log(sessionStorage.getItem("login"));
  //   navigate("/logout");
  // };
  const navigate = useNavigate();
  const name = sessionStorage.getItem("name");
  const handleLogout = () => {
    sessionStorage.clear();
  };
  var constants;

  if (process.env.REACT_APP_ENV === 'local') {
    constants = require("../lib/constant-local");
  }
  else if (process.env.REACT_APP_ENV === 'dev') {
    constants = require("../lib/constant-dev");
  }
  else if (process.env.REACT_APP_ENV === 'uat') {
    constants = require("../lib/constant-uat");
  }
  else if (process.env.REACT_APP_ENV === 'prod') {
    constants = require("../lib/constant-prod");
  }
  else {
    constants = require("../lib/constant");
  }
  const baseURL = constants.GETINOUTCOUNT;
  // const [fishescount, setFishesCount] = useState([]);
  const sessionToken = sessionStorage.getItem("sessionToken");
  const sessionDetails = {
    username: sessionToken,
  };
  const inboxhandler = () => {
    navigate("/inbox");
  };
  const [fishesCount, setFishesCount] = useState({
    totalfishin: 0,
    totalfishout: 0,
  });
  // useEffect(() => {
  //   axios
  //     .post(baseURL, sessionDetails)
  //     .then((res) => {
  //       setFishesCount(res.data);
  //       console.log(res.data.totalfishin);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  const fetchFishesCount = () => {
    axios
      .post(baseURL, sessionDetails)
      .then((res) => {
        setFishesCount(res.data);
        //console.log(res.data.totalfishin);
      })
      .catch((err) => {
        console.log(err);
        navigate("/fish/setting/2fa1");
      });
  };

  useEffect(() => {
    fetchFishesCount();
  }, []);

  useEffect(() => {
    const interval = setInterval(fetchFishesCount, 3000); // Update the count every 5 seconds
    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, []);

  const redirectSetting = () => {
    navigate({ pathname: "/fish/setting/2fa1" });
  };
  return (
    <Nav className="Navbar">
      <div className="nav-username">
        <span className="nav-user">{name}</span>
        {/* <span className="nav-user">Daisy</span> */}
        <Tooltip title="Set Up Multifactor Authentication">
          <img src={setting} alt="setting" onClick={redirectSetting} />
        </Tooltip>
      </div>

      <div className="nav-items">
        <Link to="/Inbox">
          <img src={inbin} className="inbin" alt="inbin" />
          {fishesCount.totalfishin}
        </Link>
        <Link to="/OutBox">
          <img src={outbin} className="outbin" alt="outbin" />
          {fishesCount.totalfishout}
        </Link>
      </div>
    </Nav>
  );
};

export default FishNavBar;
